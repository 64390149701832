import { FC, useContext } from "react";
import { Route, Redirect, RouteProps } from "react-router-dom";

import { PATHS } from "../constants";
import { AuthContext } from "../../auth/utils/authContext";

interface PrivateRouteProps {
  path: string;
  rest?: RouteProps;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({
  path,
  children,
  ...rest
}) => {
  const { isUserAuthorised } = useContext(AuthContext);
  return (
    <Route
      path={path}
      {...rest}
      render={({ location }) =>
        isUserAuthorised ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: PATHS.signin,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
