import React, { Dispatch, SetStateAction } from "react";

export interface AuthContextInterface {
  isUserAuthorised: boolean;
  isAuthorisationLoading: boolean;
  // shared for signing out
  setIsUserAuthorized: Dispatch<SetStateAction<boolean>>;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  isUserAuthorised: false,
  isAuthorisationLoading: true,
  setIsUserAuthorized: () => null,
});
