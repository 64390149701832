import { FC, useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash/fp";

import { AuthContext } from "./authContext";
import {
  getToken,
  TokenName,
  refreshAccessToken,
  handleAuthTokenForAxios,
  RefreshAccessTokenOutput,
  saveToken,
  removeUserSession,
  securedAxiosInterceptors,
} from "./authManager";

const REFRESH_SESSION_TIMEOUT = 30000;

export const AuthProvider: FC = ({ children }) => {
  const [isUserAuthorised, setIsUserAuthorized] = useState<boolean>(false);
  const [isAuthorisationLoading, setIsAuthorisationLoading] =
    useState<boolean>(true);

  useEffect(() => {
    const refreshTokenInterval = setInterval(async () => {
      try {
        console.log("refresh");
        const refreshToken: string | null = getToken(TokenName.refresh);

        if (refreshToken) {
          const { status, token }: RefreshAccessTokenOutput =
            await refreshAccessToken({
              refreshToken,
            });

          if (isEqual(status, 200)) {
            await saveToken(TokenName.access, token);
            handleAuthTokenForAxios(token);
          } else {
            await removeUserSession();
            setIsUserAuthorized(false);
          }
        } else {
          await removeUserSession();
          setIsUserAuthorized(false);
        }
      } catch (error) {
        await removeUserSession();
        setIsUserAuthorized(false);
      }
    }, REFRESH_SESSION_TIMEOUT);

    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    isUserAuthorised && refreshTokenInterval;

    return () => clearInterval(refreshTokenInterval);
  }, [isUserAuthorised]);

  const handleUserUnauthorized = (): void => {
    setIsUserAuthorized(false);
    setIsAuthorisationLoading(false);
  };

  const checkAuthSession = useCallback(async () => {
    const refreshToken: string | null = getToken(TokenName.refresh);

    if (refreshToken) {
      try {
        const { status, token }: RefreshAccessTokenOutput =
          await refreshAccessToken({
            refreshToken,
          });

        if (isEqual(200, status)) {
          setIsUserAuthorized(true);
          setIsAuthorisationLoading(false);
          handleAuthTokenForAxios(token);
        } else {
          handleUserUnauthorized();
        }
      } catch (error) {
        handleUserUnauthorized();
      }
    } else {
      handleUserUnauthorized();
    }
  }, []);

  const logoutSession = useCallback(() => {
    handleUserUnauthorized();
  }, []);

  useEffect(() => {
    checkAuthSession();
  }, [checkAuthSession]);

  useEffect(() => {
    securedAxiosInterceptors(logoutSession);
  }, [logoutSession]);

  useEffect(() => {
    // Set token to headers even before checking it's status to avoid 401 requests
    const accessToken: string | null = getToken(TokenName.access);
    handleAuthTokenForAxios(accessToken);
  }, []);

  return (
    <AuthContext.Provider
      value={{ isUserAuthorised, isAuthorisationLoading, setIsUserAuthorized }}
    >
      {children}
    </AuthContext.Provider>
  );
};
