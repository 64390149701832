import { FC, useEffect, useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { map } from "lodash/fp";
import { AddIcon } from "@chakra-ui/icons";

import { API_URL } from "constants/constants";
import * as Yup from "yup";
import { securedAxios } from "../../../auth/utils/authManager";
import {
  FormInput,
  InputTypes,
} from "../../../auth/components/FormInput/FormInput";

interface Organization {
  name: string;
  id: string;
}

interface FormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .max(80, "Zbyt długa nazwa organizacji")
    .required("Pole wymagane"),
});

export const Organizations: FC = () => {
  const [organizations, setOrganizations] = useState<Organization[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refetchByNumber, setRefetchByNumber] = useState<number>(0);
  const toast = useToast();

  useEffect(() => {
    const fetchReportedEvents = async () => {
      const { data }: { data: Organization[] } = await securedAxios.get(
        `${API_URL}/organization`
      );
      setOrganizations(data);
      setIsLoading(false);
    };
    fetchReportedEvents();
  }, [refetchByNumber]);

  const onSubmit = async ({ name }: { name: string }): Promise<void> => {
    try {
      await securedAxios.post(`${API_URL}/admin/organization`, {
        name,
      });
      onClose();

      toast({
        title: "Utworzono nowy typ zdarzenia!",
        status: "success",
        isClosable: true,
      });

      setRefetchByNumber(refetchByNumber + 1);
    } catch (error) {
      toast({
        title: "Wystapił nieoczekiwany bład!",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          onClick={onOpen}
          leftIcon={<AddIcon />}
          colorScheme="teal"
          variant="solid"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Utwórz organizację
        </Button>
      </div>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Lista organizacji:</Th>
          </Tr>
        </Thead>
        <Tbody>
          {map(
            (organization: Organization) => (
              <Tr key={organization.id}>
                <Td>{organization.name}</Td>
              </Tr>
            ),
            organizations
          )}

          {isLoading && (
            <Tr>
              <Td />
              <Td>
                <Spinner />
              </Td>
              <Td />
            </Tr>
          )}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Utwórz organizację</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={{
                name: "",
              }}
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValidating }) => (
                <Form>
                  <Field name="name">
                    {({
                      field,
                      meta: { touched, error },
                    }: FieldProps<FormValues>) => (
                      <FormInput
                        id="name"
                        label="Nazwa organizacji"
                        type={InputTypes.text}
                        error={error}
                        touched={touched}
                        {...field}
                      />
                    )}
                  </Field>

                  <Button
                    mt={5}
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    variant="solid"
                    type="submit"
                    isLoading={isSubmitting || isValidating}
                  >
                    Utwórz
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
