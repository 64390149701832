import { FC, useContext, useState } from "react";
import { Formik, Form, Field, FieldProps } from "formik";
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Button,
  Stack,
} from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { isEqual } from "lodash/fp";

import { FormInput, InputTypes } from "../FormInput";
import { validationSchema } from "../../utils/ValidationSchema";
import { RetrivedTokens, saveUserSession } from "../../utils/authManager";
import { API_URL } from "../../../../constants/constants";
import { AuthContext } from "../../utils/authContext";
import { PATHS } from "../../../router/constants/paths";

interface FormValues {
  email: string;
  password: string;
}

const initialValues: FormValues = {
  email: "",
  password: "",
};

export const SigninForm: FC = () => {
  const { setIsUserAuthorized } = useContext(AuthContext);
  const [requestError, setRequestError] = useState<string | unknown>();
  const history = useHistory();

  const signIn = async ({
    email,
    password,
  }: FormValues): Promise<{ status: number; data: RetrivedTokens }> => {
    const { status, data }: { status: number; data: RetrivedTokens } =
      await axios.post(`${API_URL}/login`, {
        username: email,
        password,
      });

    return { status, data };
  };

  const onSubmit = async ({ email, password }: FormValues): Promise<void> => {
    setRequestError(null);

    try {
      const { status, data } = await signIn({ email, password });

      await saveUserSession({
        refreshToken: data.refreshToken,
        token: data.token,
      });

      if (isEqual(200, status)) {
        setIsUserAuthorized(true);
        history.push(PATHS.dashboard);
      }
    } catch (error) {
      setRequestError(error);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={({ email, password }) => onSubmit({ email, password })}
    >
      {({ isSubmitting, isValidating }) => (
        <Form>
          <Stack spacing={3}>
            <Field name="email">
              {({
                field,
                meta: { touched, error },
              }: FieldProps<FormValues>) => (
                <FormInput
                  id="email"
                  label="Email"
                  type={InputTypes.email}
                  error={error}
                  touched={touched}
                  {...field}
                />
              )}
            </Field>

            <Field name="password">
              {({
                field,
                meta: { touched, error },
              }: FieldProps<FormValues>) => (
                <FormInput
                  id="password"
                  label="Hasło"
                  type={InputTypes.password}
                  error={error}
                  touched={touched}
                  {...field}
                />
              )}
            </Field>

            {Boolean(requestError) && (
              <Alert status="error">
                <AlertIcon />
                <AlertDescription>
                  Nieprawidłowe dane logowania
                </AlertDescription>
              </Alert>
            )}

            <Button
              colorScheme="teal"
              variant="solid"
              type="submit"
              isLoading={isSubmitting || isValidating}
            >
              Zaloguj się
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
