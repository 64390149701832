import { FC } from "react";
import { Center, Heading } from "@chakra-ui/react";

import { SigninForm, SignPageWrapper } from "../../components";

export const SigninPage: FC = () => {
  return (
    <SignPageWrapper>
      <Center>
        <Heading as="h4" size="lg">
          Zaloguj się
        </Heading>
      </Center>
      <SigninForm />
    </SignPageWrapper>
  );
};
