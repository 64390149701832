import { FC, useEffect, useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { isEmpty, map } from "lodash/fp";
import {ChatIcon, AddIcon, DeleteIcon} from "@chakra-ui/icons";
import * as Yup from "yup";
import moment from "moment";

import { API_URL } from "constants/constants";
import { FormInput, InputTypes } from "../../../auth/components";
import { Heading } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { securedAxios } from "../../../auth/utils/authManager";

interface Message {
  id?: string;
  title: string;
  body: string;
  author: string;
  createdAt?: string;
}

const validationSchema = Yup.object({
  title: Yup.string().required("Pole wymagane"),
  body: Yup.string().required("Pole wymagane"),
  author: Yup.string().required("Pole wymagane"),
});

export const MessagesBox = styled.div`
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  padding-bottom: 20px;
`;

export const MessageBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-bottom: 1px solid #d5d5d5;
`;

export const Messages: FC = () => {
  const [messages, setMessages] = useState<Message[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [operation, setOperation] = useState<string>('edit');
  const [refetchByNumber, setRefetchByNumber] = useState<number>(0);
  const [currentMessage, setCurrentMessage] = useState<Message | null>();
  const toast = useToast();

  useEffect(() => {
    const fetchReportedEvents = async () => {
      const { data }: { data: Message[] } = await securedAxios.get(
        `${API_URL}/message`
      );
      setMessages(data);
      setIsLoading(false);
    };
    fetchReportedEvents();
  }, [refetchByNumber]);

  const onSubmit = async ({ title, body, author, id }: Message): Promise<void> => {
    try {
      if (operation === 'edit' || operation === 'create') {
        await securedAxios.post(`${API_URL}/admin/message${ id ? "/" + id : ""}`, {
          title,
          body,
          author,
        });
        onClose();

        let message =
            operation === 'edit' ?
                'Zedytowano komunikat' :
                'Wysłano nowy komunikat';

        toast({
          title: message,
          status: "success",
          isClosable: true,
        });
      } else {
        await securedAxios.delete(`${API_URL}/admin/message${ id ? "/" + id : ""}`);
        onClose();

        toast({
          title: "Usunięto komunikat",
          status: "success",
          isClosable: true,
        });
      }

      setRefetchByNumber(refetchByNumber + 1);
    } catch (error) {
      toast({
        title: "Wystapił nieoczekiwany bład!",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          onClick={() => {
            onOpen();
            setOperation('create');
            setCurrentMessage(null);
          }}
          leftIcon={<ChatIcon />}
          colorScheme="teal"
          variant="solid"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Utwórz komunikat
        </Button>
      </div>

      <Heading size="md">Komunikaty:</Heading>
      <MessagesBox>
        {map(
          (message: Message) => (
            <MessageBox key={message.id}>
              <Heading style={{marginBottom: '10px'}} size="md">Tytuł: {message.title}</Heading>
              <Text style={{fontWeight: 'bold'}}>Treść:</Text>
              <Text style={{marginBottom: '15px'}}>{message.body}</Text>
              <Text style={{fontWeight: 'bold'}}>Nadawca:</Text>
              <Text style={{marginBottom: '15px'}}>{message.author}</Text>
              <Text style={{fontWeight: 'bold'}}>Data utworzenia:</Text>
              <Text>{moment(message.createdAt).format('D-MM-Y, H:mm:ss ')}</Text>
              <div>
                <Button
                    onClick={() => {
                      onOpen();
                      setOperation('edit');
                      setCurrentMessage(message);
                    }}
                    leftIcon={<ChatIcon />}
                    colorScheme="teal"
                    variant="solid"
                    style={{ marginTop: 30, marginBottom: 30, width: 120}}
                >
                  Edytuj
                </Button>
                <Button
                    onClick={() => {
                      onOpen();
                      setOperation('delete');
                      setCurrentMessage(message);
                    }}
                    leftIcon={<DeleteIcon />}
                    colorScheme="red"
                    variant="solid"
                    style={{ marginLeft: 20, marginTop: 30, marginBottom: 30, width: 120}}
                >
                  Usuń
                </Button>
              </div>
            </MessageBox>
          ),
          messages
        )}
      </MessagesBox>

      {isLoading && <Spinner />}
      {!isLoading && isEmpty(messages) && (
        <div style={{ flex: 1, textAlign: "center" }}>
          <Text fontSize="2xl">Brak komunikatów</Text>
        </div>
      )}

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {operation === 'create' ? "Utwórz komunikat" : ''}
            {operation === 'edit' ? "Edytuj komunikat" : ''}
            {operation === 'delete' ? "Usuń komunikat" : ''}
          </ModalHeader>
          <ModalCloseButton />
            <ModalBody>
              <Formik
                initialValues={currentMessage || {
                  title: "",
                  body: "",
                  author: "",
                }}
                validationSchema={validationSchema}
                onSubmit={onSubmit}
              >
                {({ isSubmitting, isValidating }) => (
                  <Form>
                    <Field name="title">
                      {({
                        field,
                        meta: { touched, error },
                      }: FieldProps<Message>) => (
                        <FormInput
                          id="title"
                          label="Tytuł komunikatu"
                          type={InputTypes.text}
                          error={error}
                          touched={touched}
                          {...field}
                        />
                      )}
                    </Field>
                    <br />
                    <Field name="body">
                      {({
                        field,
                        meta: { touched, error },
                      }: FieldProps<Message>) => (
                        <FormInput
                          id="body"
                          label="Treść komunikatu"
                          type={InputTypes.textarea}
                          error={error}
                          touched={touched}
                          {...field}
                        />
                      )}
                    </Field>
                    <Field name="author">
                      {({
                          field,
                          meta: { touched, error },
                        }: FieldProps<Message>) => (
                          <FormInput
                              id="author"
                              label="Nadawca"
                              type={InputTypes.text}
                              error={error}
                              touched={touched}
                              {...field}
                          />
                      )}
                    </Field>
                    {operation === 'edit' || operation === 'create' ? (
                        <Button
                            mt={5}
                            leftIcon={<AddIcon />}
                            colorScheme="teal"
                            variant="solid"
                            type="submit"
                            isLoading={isSubmitting || isValidating}
                        >
                          Wyślij
                        </Button>
                      ) : (
                          <Button
                              mt={5}
                              leftIcon={<DeleteIcon />}
                              colorScheme="red"
                              variant="solid"
                              type="submit"
                              isLoading={isSubmitting || isValidating}
                          >
                            Usuń
                          </Button>
                        )}
                  </Form>
                )}
              </Formik>
            </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
