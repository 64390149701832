import { FC, useEffect, useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  IconButton,
  ModalOverlay,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { isEmpty, map } from "lodash/fp";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

import { API_URL } from "constants/constants";
import * as Yup from "yup";
import { securedAxios } from "../../../auth/utils/authManager";
import {
  FormInput,
  InputTypes,
} from "../../../auth/components/FormInput/FormInput";

export interface Category {
  id?: string;
  name: string;
  color: string;
}

interface FormValues {
  name: string;
}

const validationSchema = Yup.object({
  name: Yup.string()
    .max(80, "Zbyt długa nazwa kategorii")
    .required("Pole wymagane"),
  color: Yup.string().required("Pole wymagane"),
});

export const Categories: FC = () => {
  const [categories, setCategories] = useState<Category[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentCategory, setCurrentCategory] = useState<Category | null>();
  const [refetchByNumber, setRefetchByNumber] = useState<number>(0);
  const toast = useToast();

  useEffect(() => {
    const fetchReportedEvents = async () => {
      const { data }: { data: Category[] } = await securedAxios.get(
        `${API_URL}/event-category`
      );
      setCategories(data);
      setIsLoading(false);
    };
    fetchReportedEvents();
  }, [refetchByNumber]);

  const onSubmit = async ({ name, color, id }: Category): Promise<void> => {
    try {
      await securedAxios.post(`${API_URL}/admin/event-category${ id ? "/" + id : ""}`, {
        name,
        color,
      });
      onClose();

      toast({
        title: id ? "Zaktualizowano kategorię" : "Utworzono nową kategorię!",
        status: "success",
        isClosable: true,
      });

      setRefetchByNumber(refetchByNumber + 1);
    } catch (error) {
      toast({
        title: "Wystapił nieoczekiwany bład!",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          onClick={() => {
            onOpen();
            setCurrentCategory(null);
          }}
          leftIcon={<AddIcon />}
          colorScheme="teal"
          variant="solid"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Utwórz kategorię
        </Button>
      </div>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nazwa:</Th>
            <Th>Kolor:</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {map(
            (category: Category) => (
              <Tr key={category.id}>
                <Td>{category.name}</Td>
                <Td>
                  <div
                    style={{
                      width: 15,
                      height: 15,
                      backgroundColor: category.color,
                    }}
                  />
                </Td>
                <Td style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      onOpen();
                      setCurrentCategory(category);
                    }}
                    aria-label="Edit category"
                    icon={<EditIcon />}
                  />
                </Td>
              </Tr>
            ),
            categories
          )}

          {isLoading && (
            <Tr>
              <Td />
              <Td>
                <Spinner />
              </Td>
              <Td />
            </Tr>
          )}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentCategory?.id ? "Edytuj kategorię" : "Utwórz kategorię"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={
                currentCategory || {
                  name: "",
                  color: "",
                }
              }
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValidating, initialValues }) => (
                <Form>
                  <Field name="name">
                    {({
                      field,
                      meta: { touched, error },
                    }: FieldProps<FormValues>) => (
                      <FormInput
                        id="name"
                        label="Nazwa kategorii"
                        type={InputTypes.text}
                        error={error}
                        touched={touched}
                        {...field}
                      />
                    )}
                  </Field>
                  <Field name="color">
                    {({
                      field,
                      meta: { touched, error },
                    }: FieldProps<FormValues>) => (
                      <FormControl isInvalid={!isEmpty(error) && touched}>
                        <FormLabel htmlFor="color">Kolor</FormLabel>
                        <Select
                          id="color"
                          placeholder="Wybierz kolor"
                          onChange={field.onChange}
                          isInvalid={Boolean(error && touched)}
                          defaultValue={initialValues.color}
                        >
                          <option value="red" style={{color: 'red'}}>&#9724; Czerwony</option>
                          <option value="blue" style={{color: 'blue'}}>&#9724; Niebieski</option>
                          <option value="green" style={{color: 'green'}}>&#9724; Zielony</option>
                          <option value="yellow" style={{color: 'yellow'}}>&#9724; Żółty</option>
                          <option value="orange" style={{color: 'orange'}}>&#9724; Pomarańczowy</option>
                          <option value="indigo" style={{color: 'indigo'}}>&#9724; Indygo</option>
                          <option value="teal" style={{color: 'teal'}}>&#9724; Morski</option>
                          <option value="linen" style={{color: 'linen'}}>&#9724; Lniany</option>
                          <option value="violet" style={{color: 'violet'}}>&#9724; Fioletowy</option>
                        </Select>

                        {!isEmpty(error) && touched && (
                          <FormErrorMessage>{error}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>

                  <Button
                    mt={5}
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    variant="solid"
                    type="submit"
                    isLoading={isSubmitting || isValidating}
                  >
                    {currentCategory?.id ? "Zapisz zmiany" : "Utwórz"}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
