import { API_URL } from "./../../../constants/constants";
// DISABLE FOR DEVELOPMENT PROCESS
/* eslint-disable */
import axios from "axios";
import { isEqual } from "lodash/fp";
import jwt_decode from "jwt-decode";
import {bool} from "yup";

export interface RetrivedTokens {
  refreshToken: string;
  token: string;
}

export interface DecodedToken {
  iat: number;
  exp: number;
  roles: string[];
  username: string;
}

export enum TokenName {
  refresh = "refreshToken",
  access = "token",
}

// Secured Axios instance to authenticated requests
export const securedAxios = axios.create();

export const securedAxiosInterceptors = (callback: () => void) => {
  securedAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      if (isEqual(401, error.response.status)) {
        callback();
        removeUserSession();
      }

      return Promise.reject(error);
    }
  );
};

// Set access token to global axios configuration
export const handleAuthTokenForAxios = (accessToken: string | null): void => {
  // @ts-ignore
  securedAxios.defaults.headers.common["Authorization"] = accessToken
    ? `Bearer ${accessToken}`
    : accessToken;
};

export const saveToken = (tokenName: string, token: string): void => {
  localStorage.setItem(tokenName, token);
};

export const saveUserSession = async ({
  refreshToken,
  token,
}: RetrivedTokens): Promise<void> => {
  await saveToken(TokenName.refresh, refreshToken);
  await saveToken(TokenName.access, token);
  return handleAuthTokenForAxios(token);
};

export const removeUserSession = async (): Promise<void> => {
  await localStorage.removeItem(TokenName.access);
  await localStorage.removeItem(TokenName.refresh);

  return handleAuthTokenForAxios(null);
};

export const isAdmin = (): Boolean => {
  return getRoles().includes('ROLE_ADMIN')
}

export const getRoles = (): string[] => {
  let token = getToken(TokenName.access);
  if (token) {
    let decodedToken : DecodedToken = jwt_decode(token)
    return decodedToken.roles;
  }

  return [];
}

export const getToken = (tokenName: string): string | null => {
  return localStorage.getItem(tokenName);
};

export interface RefreshAccessTokenOutput {
  status: number;
  token: string;
}

export const refreshAccessToken = async ({
  refreshToken,
}: {
  refreshToken: string;
}): Promise<RefreshAccessTokenOutput|any> => {
  try {
    const { data, status }: { data: RetrivedTokens; status: number } =
      await securedAxios.post(`${API_URL}/token/refresh`, {
        refreshToken,
      });

    return {
      status,
      token: data.token,
    };
  } catch (error) {
    return error;
  }
};
