import { FC, useContext } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { AuthContext, DashboardPage, SigninPage } from "domains";
import { PATHS } from "../constants/paths";
import { PrivateRoute } from "./PrivateRoute";
import { Spinner } from "@chakra-ui/react";
import styled from "@emotion/styled";

export const LoadingBox = styled.div`
  height: 100vh;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`;

export const AppRouter: FC = () => {
  const { isAuthorisationLoading } = useContext(AuthContext);

  if (isAuthorisationLoading) {
    return (
      <LoadingBox>
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </LoadingBox>
    );
  }

  return (
    <Router>
      <Switch>
        <Route path={PATHS.signin}>
          <SigninPage />
        </Route>
        <PrivateRoute path={PATHS.dashboard}>
          <DashboardPage />
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
