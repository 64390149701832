import { ChakraProvider } from "@chakra-ui/react";

import { AuthProvider } from "domains/auth/utils/authProvider";
import { AppRouter } from "./domains/router/components/AppRouter";

function App() {
  return (
    <ChakraProvider>
      <AuthProvider>
        <AppRouter />
      </AuthProvider>
    </ChakraProvider>
  );
}

export default App;
