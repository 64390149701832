import { FC, useEffect, useState } from "react";
import {
  GoogleMap,
  HeatmapLayer,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  useDisclosure,
  Text,
  useToast,
} from "@chakra-ui/react";
import { isEqual } from "lodash/fp";
import styled from "@emotion/styled";

import { API_URL, GM_KEY } from "constants/constants";
import { securedAxios } from "../../../auth/utils/authManager";
import { Category } from "../Categories";

export interface EventType {
  name: string;
  id: string;
  priority: number;
  category: Category;
}

export interface LatLng {
  latitude: number;
  longitude: number;
}

export interface Organization {
  id: string;
  name: string;
  longitude: number;
  latitude: number;
}

interface ReportedEvent {
  id: string;
  color: string;
  name: string;
  street: string;
  city: string;
  status: string;
  confirmations: number;
  coordinate: LatLng;
  eventType: EventType;
}

const containerStyle = {
  width: "5000px",
  height: "600px",
};

let center = {
  lat: 53.6944,
  lng: 17.5569,
};

enum Mode {
  markers,
  heatmap,
}

const SideMenu = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bold = styled.div`
  font-weight: bold;
  display: inline-flex;
`;

export const Events: FC = () => {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GM_KEY,
    libraries: ["places", "visualization"],
  });
  const [reportedEvents, setReportedEvents] = useState<ReportedEvent[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [mode, setMode] = useState<Mode | string>(Mode.markers);
  const [coordinates, setCoordinates] = useState(center)
  const [eventMode, setEventMode] = useState<string>('active');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentEvent, setCurrentEvent] = useState<ReportedEvent | void>();
  const [refetchByNumber, setRefetchByNumber] = useState<number>(0);
  const toast = useToast();
  const setEventTypesVisible = async() => {
    if (eventMode === 'active') {
      fetchAllReportedEvents()
      setEventMode('all')
      return;
    }
    setEventMode('active')
    fetchReportedEvents()
  }
  const fetchReportedEvents = async () => {
    const { data }: { data: ReportedEvent[] } = await securedAxios.get(
        `${API_URL}/admin/event/active`
    );
    setReportedEvents(data);
    setIsLoading(false);
  };
  const fetchAllReportedEvents = async () => {
    const { data }: { data: ReportedEvent[] } = await securedAxios.get(
        `${API_URL}/admin/event/all`
    );
    setReportedEvents(data);
    setIsLoading(false);
  };
  const fetchOrganization = async () => {
      const { data }: { data: Organization } = await securedAxios.get(
          `${API_URL}/organization/me`
      );
      setCoordinates({
        lat: data ? data.latitude : center.lat,
        lng: data ? data.longitude : center.lng,
      })
  }

  useEffect(() => {
    fetchOrganization();
  }, [refetchByNumber])

  useEffect(() => {
    fetchReportedEvents();
  }, [refetchByNumber]);

  const handleMarketOpen = (currentEvent: ReportedEvent): void => {
    onOpen();
    setCurrentEvent(currentEvent);
  };

  const handleRemoveEvent = async (): Promise<void> => {
    try {
      await securedAxios.delete(`${API_URL}/admin/event/${currentEvent?.id}`);
      onClose();

      toast({
        title: "Zdarzenie zostało usunięte!",
        status: "success",
        isClosable: true,
      });

      setRefetchByNumber(refetchByNumber + 1);
    } catch (error) {
      toast({
        title: "Wystapił nieoczekiwany bład!",
        status: "error",
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div style={{ display: "flex" }}>
      {(isLoaded && center) ? (
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={coordinates}
          zoom={16}
          key={refetchByNumber}
        >
          {isEqual(Mode.markers, mode) && (
            <>
              {reportedEvents?.map((reportedEvent: ReportedEvent) => (
                <Marker
                  onLoad={(marker) => {
                    const customIcon = (opts: any) =>
                      Object.assign(
                        {
                          path: "M7.8,1.3L7.8,1.3C6-0.4,3.1-0.4,1.3,1.3c-1.8,1.7-1.8,4.6-0.1,6.3c0,0,0,0,0.1,0.1 l3.2,3.2l3.2-3.2C9.6,6,9.6,3.2,7.8,1.3C7.9,1.4,7.9,1.4,7.8,1.3z M4.6,5.8c-0.7,0-1.3-0.6-1.3-1.4c0-0.7,0.6-1.3,1.4-1.3 c0.7,0,1.3,0.6,1.3,1.3 C5.9,5.3,5.3,5.9,4.6,5.8z",
                          fillColor: reportedEvent.color,
                          fillOpacity: 1,
                          strokeColor: "white",
                          strokeWeight: 1,
                          scale: 3.5,
                        },
                        opts
                      );

                    marker.setIcon(
                      customIcon({
                        fillColor: reportedEvent.color,
                        strokeColor: "white",
                      })
                    );
                  }}
                  key={reportedEvent.id}
                  title={reportedEvent.name}
                  position={{
                    lat: reportedEvent.coordinate.latitude,
                    lng: reportedEvent.coordinate.longitude,
                  }}
                  onClick={() => handleMarketOpen(reportedEvent)}
                />
              ))}
            </>
          )}
          {isEqual(Mode.heatmap, mode) && (
            <HeatmapLayer
              // @ts-ignore
              data={reportedEvents?.map(
                (reportedEvents) =>
                  new google.maps.LatLng(
                    reportedEvents.coordinate.latitude,
                    reportedEvents.coordinate.longitude
                  )
              )}
            />
          )}
        </GoogleMap>
      ) : (
        <></>
      )}
      <SideMenu>
        <Button
          variant={isEqual(Mode.markers, mode) ? "solid" : "outline"}
          onClick={() => setMode(Mode.markers)}
          colorScheme="blue"
          mb={3}
          ml={5}
        >
          Punktory
        </Button>
        <Button
          variant={isEqual(Mode.heatmap, mode) ? "solid" : "outline"}
          onClick={() => setMode(Mode.heatmap)}
          colorScheme="red"
          mb={3}
          ml={5}
        >
          Mapa ciepła
        </Button>
        <Button
            variant={isEqual(eventMode, 'all') ? "outline" : "solid"}
            onClick={() => setEventTypesVisible()}
            colorScheme="green"
            ml={5}
        >
          {eventMode === 'all' ? 'Wszystkie Wydarzenia' : 'Aktywne Wydarzenia'}
        </Button>
      </SideMenu>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader></ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>
              nazwa: <Bold>{currentEvent?.name}</Bold>
            </Text>
            <Text>
              typ zdarzenia: <Bold>{currentEvent?.eventType.name}</Bold>
            </Text>
            <Text>
              ulica: <Bold>{currentEvent?.street}</Bold>
            </Text>
            <Text>
              miasto: <Bold>{currentEvent?.city}</Bold>
            </Text>
            <Text>
              współrzędne :{" "}
              <Bold>
                {currentEvent?.coordinate.longitude} /{" "}
                {currentEvent?.coordinate.latitude}
              </Bold>
            </Text>
            <Text>
              status: <Bold>{currentEvent?.city}</Bold>
            </Text>
            <Text>
              potwierdzenia: <Bold>{currentEvent?.confirmations}</Bold>
            </Text>
          </ModalBody>

          <ModalFooter>
            <Button
              colorScheme="red"
              variant="ghost"
              mr={3}
              onClick={handleRemoveEvent}
            >
              Usuń zdarzenie
            </Button>
            <Button colorScheme="blue" onClick={onClose}>
              Zamknij
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};
