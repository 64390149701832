import { FC } from "react";
import { isEmpty, isEqual } from "lodash/fp";

import {
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Textarea,
} from "@chakra-ui/react";

export enum InputTypes {
  password = "password",
  email = "email",
  text = "text",
  textarea = "textarea",
}

interface Props {
  id: string;
  type: InputTypes;
  label: string;
  error?: string;
  touched?: boolean;
}

export const FormInput: FC<Props> = ({
  id,
  type,
  label,
  error,
  touched,
  ...props
}) => (
  <FormControl id={id} isInvalid={!isEmpty(error) && touched}>
    <FormLabel>{label}</FormLabel>
    {isEqual(InputTypes.textarea, type) ? (
      <Textarea {...props} />
    ) : (
      <Input type={type} {...props} />
    )}
    {!isEmpty(error) && touched && <FormErrorMessage>{error}</FormErrorMessage>}
  </FormControl>
);
