import { useEffect, useState } from "react";
import { Formik, Field, Form, FieldProps } from "formik";
import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Spinner,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { isEmpty, map, toNumber } from "lodash/fp";
import { AddIcon, EditIcon } from "@chakra-ui/icons";

import { API_URL } from "constants/constants";
import * as Yup from "yup";
import { securedAxios } from "../../../auth/utils/authManager";
import {
  FormInput,
  InputTypes,
} from "../../../auth/components/FormInput/FormInput";
import { Category } from "../Categories";

interface EventType {
  name: string;
  priority: number;
  lifespanHours: number;
  id: string;
  category: Category;
}

interface FormValues {
  id?: string;
  name: string;
  priority: number;
  lifespanHours: number;
  eventCategoryId: string;
}

const validationSchema = Yup.object({
  name: Yup.string().max(35, "Zbyt długa nazwa typu").required("Pole wymagane"),
  priority: Yup.number()
    .positive("Zbyt niski priorytet")
    .required("Pole wymagane"),
  lifespanHours: Yup.number()
    .positive("Zbyt krótki okres czasu")
    .required("Pole wymagane"),
  eventCategoryId: Yup.string().required("Pole wymagane"),
});

export const EventTypes = () => {
  const [reportedEventTypes, setReportedEventTypes] = useState<EventType[]>();
  const [categories, setCategories] = useState<EventType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentEventType, setCurrentEventType] = useState<EventType | null>();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refetchByNumber, setRefetchByNumber] = useState<number>(0);
  const toast = useToast();
  const fetchReportedEvents = async () => {
  const { data }: { data: EventType[] } = await securedAxios.get(
        `${API_URL}/event-type`
    );
    setReportedEventTypes(data);
    setIsLoading(false);
  };
  const fetchCategoriesEvents = async () => {
    const { data }: { data: EventType[] } = await securedAxios.get(
        `${API_URL}/event-category`
    );
    setCategories(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchReportedEvents();
  }, [refetchByNumber]);

  useEffect(() => {
    fetchCategoriesEvents();
  }, [refetchByNumber]);

  const onSubmit = async ({
    name,
    priority,
    lifespanHours,
    eventCategoryId,
    id,
  }: FormValues): Promise<void> => {
    try {
      await securedAxios.post(`${API_URL}/admin/event-type${ id ? "/" + id : ""}`, {
        name,
        priority: toNumber(priority),
        lifespanHours: toNumber(lifespanHours),
        eventCategoryId,
      });
      onClose();

      toast({
        title: id ? "Zapisano zmiany" : "Utworzono nowy typ zdarzenia!",
        status: "success",
        isClosable: true,
      });

      setRefetchByNumber(refetchByNumber + 1);
    } catch (error) {
      toast({
        title: "Wystapił nieoczekiwany bład!",
        status: "error",
        isClosable: true,
      });
    }
  };

  return (
    <div>
      <div style={{ justifyContent: "center", display: "flex" }}>
        <Button
          onClick={() => {
            fetchCategoriesEvents()
            onOpen();
            setCurrentEventType(null);
          }}
          leftIcon={<AddIcon />}
          colorScheme="teal"
          variant="solid"
          style={{ marginTop: 30, marginBottom: 30 }}
        >
          Utwórz typ zgłoszenia
        </Button>
        <br />
      </div>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Lista typów zgłoszeń:</Th>
            <Th>Czas trwania:</Th>
            <Th>Priorytet:</Th>
            <Th>Kategoria:</Th>
            <Th />
          </Tr>
        </Thead>
        <Tbody>
          {map(
            (event: EventType) => (
              <Tr key={event.id}>
                <Td>{event.name}</Td>
                <Td>{event.lifespanHours}</Td>
                <Td>{event.priority}</Td>
                <Td>{event.category.name}</Td>
                <Td style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={() => {
                      onOpen();
                      setCurrentEventType(event);
                    }}
                    aria-label="Edit category"
                    icon={<EditIcon />}
                  />
                </Td>
              </Tr>
            ),
            reportedEventTypes
          )}

          {isLoading && (
            <Tr>
              <Td />
              <Td>
                <Spinner />
              </Td>
              <Td />
            </Tr>
          )}
        </Tbody>
      </Table>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            {currentEventType?.id
              ? "Edytuj typ zgłoszenia"
              : "Utwórz typ zgłoszenia"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Formik
              initialValues={
                currentEventType
                  ? {
                      ...currentEventType,
                      eventCategoryId: `${currentEventType.category.id}`,
                    }
                  : {
                      name: "",
                      priority: 100,
                      lifespanHours: 8,
                      eventCategoryId: "",
                    }
              }
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {({ isSubmitting, isValidating, initialValues }) => (
                <Form>
                  <Field name="name">
                    {({
                      field,
                      meta: { touched, error },
                    }: FieldProps<FormValues>) => (
                      <FormInput
                        id="name"
                        label="Typ zgłosznia"
                        type={InputTypes.text}
                        error={error}
                        touched={touched}
                        {...field}
                      />
                    )}
                  </Field>
                  <br />
                  <Field name="eventCategoryId">
                    {({
                      field,
                      meta: { touched, error },
                    }: FieldProps<FormValues>) => (
                      <FormControl isInvalid={!isEmpty(error) && touched}>
                        <FormLabel htmlFor="eventCategoryId">
                          Wybierz kategorię
                        </FormLabel>
                        <Select
                          id="eventCategoryId"
                          placeholder="Wybierz kategorię"
                          onChange={field.onChange}
                          isInvalid={Boolean(error && touched)}
                          defaultValue={initialValues?.eventCategoryId}
                        >
                          {map(
                            (category) => (
                              <option key={category.id} value={category.id}>
                                {category.name}
                              </option>
                            ),
                            categories
                          )}
                        </Select>

                        {!isEmpty(error) && touched && (
                          <FormErrorMessage>{error}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>

                  <br />
                  <Field name="priority">
                    {({
                      field,
                      meta: { touched, error },
                      form: { setFieldValue },
                    }: FieldProps<FormValues>) => (
                      <FormControl isInvalid={!isEmpty(error) && touched}>
                        <FormLabel htmlFor="priority">Priorytet</FormLabel>

                        <NumberInput
                          id="priority"
                          defaultValue={`${field.value}`}
                          onChange={(value) => {
                            setFieldValue("priority", value);
                          }}
                          min={0}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>

                        {!isEmpty(error) && touched && (
                          <FormErrorMessage>{error}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>
                  <br />

                  <Field name="lifespanHours">
                    {({
                      field,
                      meta: { touched, error },
                      form: { setFieldValue },
                    }: FieldProps<FormValues>) => (
                      <FormControl isInvalid={!isEmpty(error) && touched}>
                        <FormLabel htmlFor="lifespanHours">
                          Czas wyświetlania (w godzinach)
                        </FormLabel>

                        <NumberInput
                          id="lifespanHours"
                          defaultValue={`${field.value}`}
                          onChange={(value) => {
                            setFieldValue("lifespanHours", value);
                          }}
                          min={0}
                        >
                          <NumberInputField />
                          <NumberInputStepper>
                            <NumberIncrementStepper />
                            <NumberDecrementStepper />
                          </NumberInputStepper>
                        </NumberInput>

                        {!isEmpty(error) && touched && (
                          <FormErrorMessage>{error}</FormErrorMessage>
                        )}
                      </FormControl>
                    )}
                  </Field>

                  <Button
                    mt={5}
                    leftIcon={<AddIcon />}
                    colorScheme="teal"
                    variant="solid"
                    type="submit"
                    isLoading={isSubmitting || isValidating}
                  >
                    {currentEventType?.id ? "Zapisz zmiany" : "Dodaj"}
                  </Button>
                </Form>
              )}
            </Formik>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};
