import { FC, useContext } from "react";
import {
  Heading,
  Text,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Button,
} from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";

import { PageWrapper } from "components";
import {
  Events,
  Categories,
  Messages,
  EventTypes,
  Statistics,
} from "../components";

import styled from "@emotion/styled";
import { AuthContext } from "../../auth/utils/authContext";
import { removeUserSession, isAdmin } from "domains/auth/utils/authManager";

export const Header = styled.div`
  flex-direction: row;
  flex: 1;
  display: flex;
  justify-content: space-between;
`;

export const DashboardPage: FC = () => {
  const { setIsUserAuthorized } = useContext(AuthContext);

  const handleLogout = (): void => {
    setIsUserAuthorized(false);
    removeUserSession();
  };

  return (
    <PageWrapper>
      <Header>
        <div>
          <Heading>SafeTy</Heading>
          <Text fontSize="xl">Panel administracyjny</Text>
        </div>
        <Button
          rightIcon={<ArrowForwardIcon />}
          colorScheme="blue"
          variant="outline"
          onClick={handleLogout}
        >
          Wyloguj się
        </Button>
      </Header>
      <br />
      <Tabs>
        <TabList>
          <Tab>Zgłoszenia</Tab>
          {isAdmin() ? (<Tab>Kategorie</Tab>) : ''}
          {isAdmin() ? (<Tab>Typy zgłoszeń</Tab>) : ''}
          <Tab>Komunikaty</Tab>
          <Tab>Statystyki</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Events />
          </TabPanel>
          {isAdmin() ? (
          <TabPanel>
            <Categories />
          </TabPanel>) : ''}
          {isAdmin() ? (
          <TabPanel>
            <EventTypes />
          </TabPanel>) : ''}
          <TabPanel>
            <Messages />
          </TabPanel>
          <TabPanel>
            <Statistics />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </PageWrapper>
  );
};
