import { FC, useEffect, useState } from "react";
import { Spinner, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { map } from "lodash/fp";

import { API_URL } from "constants/constants";
import { securedAxios } from "../../../auth/utils/authManager";

interface EventType {
  eventTypeName: string;
  count: number;
}

export const Statistics: FC = () => {
  const [statistics, setStatistics] = useState<EventType[]>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const fetchReportedEvents = async () => {
    const { data }: { data: EventType[] } = await securedAxios.get(
        `${API_URL}/admin/stats/event`
    );
    setStatistics(data);
    setIsLoading(false);
  };

  useEffect(() => {
    fetchReportedEvents();
  }, []);

  return (
    <div>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Nazwa zdarzenia</Th>
            <Th>Ilość zdarzeń</Th>
          </Tr>
        </Thead>
        <Tbody>
          {map(
            (statistic: EventType) => (
              <Tr key={`${Object.keys(statistic)}`}>
                <Td>{statistic.eventTypeName}</Td>
                <Td>{statistic.count}</Td>
              </Tr>
            ),
            statistics
          )}

          {isLoading && (
            <Tr>
              <Td />
              <Td>
                <Spinner />
              </Td>
              <Td />
            </Tr>
          )}
        </Tbody>
      </Table>
    </div>
  );
};
