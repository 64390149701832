import { FC } from "react";
import { Container, Heading } from "@chakra-ui/react";
import { Link } from "react-router-dom";

import { PageWrapper } from "components";

export const SignPageWrapper: FC = ({ children }) => {
  return (
    <PageWrapper>
      <Link to="/">
        <Heading size="sm">SafeTy</Heading>
      </Link>
      <Container marginTop="100px">{children}</Container>
    </PageWrapper>
  );
};
